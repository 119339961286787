@import "../../base.scss";

.NavBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: $page-width;
  height: 100%;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;
}

.Logo {
  display: flex;
  align-items: center;

  span {
    @include label();
  }

  &:hover {
    span {
      color: $color-grey-dark;
    }
  }
}

@media screen and (max-width: $small-screen-breakpoint) {
  .NavBar {
    padding: 0 10px;
  }
}
