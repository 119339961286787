@import "../../../base.scss";

.Card {
  width: 200px;
  border-radius: 6px;
  background-color: $color-white;
  box-shadow: $shadow;
  overflow: hidden;
  transition: box-shadow 250ms ease;
}

.Image {
  width: 200px;
  height: 150px;
  background-color: $color-blue-transparent;
  object-fit: cover;
  object-position: center;
}

.Content {
  padding: 10px 20px 20px;

  h2 {
    @include text-large();
  }
}

.Date {
  @include text-small();
  color: $color-grey-dark;
}

@media screen and (max-width: $small-screen-breakpoint) {
  .Card {
    width: 100%;

    .Image {
      width: 100%;
    }
  }
}
