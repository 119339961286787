@import "../../../base.scss";

.Teaser {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 12px;
  background-color: $color-white;
  box-shadow: $shadow;

  &.ImageFirst {
    .Content {
      align-items: flex-end;
      text-align: right;
    }
  }

  &.ContentFirst {
    flex-direction: row-reverse;
  }
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 30px;
}

.Title {
  @include title-small();
}

.Text {
  @include text-medium();
}

.Image {
  max-width: 120px;
  max-height: 120px;
  padding: 30px;
}

@media screen and (max-width: $small-screen-breakpoint) {
  .Teaser {
    &.ImageFirst,
    &.ContentFirst {
      .Content {
        align-items: stretch;
        text-align: left;
      }
    }
  }

  .Image {
    display: none;
  }
}
