@import "./base.scss";

.App {
  height: 100vh;
  height: 100dvh;
  max-height: -webkit-fill-available;
  min-width: 320px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.NavBarContainer {
  flex-shrink: 0;
  height: 60px;
  box-sizing: border-box;
  border-bottom: 1px solid $color-grey-light;
}

.ContentContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}

.Content {
  flex-grow: 1;
  width: 100%;
  max-width: $page-width;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

.FooterContainer {
  flex-shrink: 0;
  width: 100%;
  height: 120px;
}
