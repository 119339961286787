@import "../../base.scss";

.Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Intro {
  display: flex;
  align-items: center;
  justify-content: center;
}

.BubbleContainer {
  max-width: 360px;
}

.Content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@media screen and (max-width: $small-screen-breakpoint) {
  .Intro {
    flex-direction: column;
  }

  .Content {
    flex-direction: column-reverse;
  }
}
