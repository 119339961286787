@import "../../../base.scss";

.Intro {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.Image {
  max-width: 320px;
}

.Title {
  @include title-large();
}

.Text {
  @include text-medium();
}

@media screen and (max-width: $small-screen-breakpoint) {
  .Intro {
    flex-direction: column;
  }

  .Content {
    align-items: center;
    text-align: center;
  }
}
