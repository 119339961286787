@import "../../base.scss";

.List {
  margin: 0;
  padding: 0 0 0 25px;
  list-style-type: none;
}

.ListItem {
  @include text-medium();
  color: $color-black;
  margin-bottom: 10px;
  position: relative;

  &.Interactive {
    font-weight: 500;
    color: $color-grey-dark;

    &:hover {
      cursor: pointer;
      color: $color-black;
    }

    &.Active {
      color: $color-black;
    }
  }
}

.Marker {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: -5px;
  transform: translateX(-100%);
  object-fit: contain;
}
