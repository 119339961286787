body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(
      180deg,
      rgba(186, 208, 247, 0) 0%,
      rgba(186, 208, 247, 0.25) 100%
    ),
    #ffffff;
  color: #333333;
}

@font-face {
  font-family: "Giddyup";
  font-weight: 400;
  src: url("/public/assets/fonts/GiddyupStd.otf");
}

h1,
h2,
h3,
p {
  margin: 0;
}

a {
  text-decoration: none;
  color: #333333;
}

button {
  border: none;
  background-color: transparent;
}

img {
  vertical-align: middle;
}

:focus-visible {
  outline-color: #7a9dea;
}
