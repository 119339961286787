@import "../../base.scss";

.Button {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 40px;
  padding: 0 40px;
  border: 2px transparent solid;
  border-radius: 20px;

  span {
    @include button-label();
  }

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline: none;
    border-color: $color-blue-dark;
    box-shadow: 0 0 0 1px $color-blue-medium;
  }

  &.Primary {
    background-color: $color-green-medium;

    span {
      color: $color-white;
    }

    &:hover {
      background-color: $color-green-dark;
    }
  }

  &.Secondary {
    border: 2px $color-blue-dark solid;

    span {
      color: $color-blue-dark;
    }

    &:hover {
      background-color: $color-blue-transparent;
    }
  }
}
