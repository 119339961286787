@import "../../base.scss";

.TextInput {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  background-color: $color-white;
  border-radius: 8px;
  border: 1px solid $color-grey-medium;
  outline: none;
  box-shadow: none;
  @include text-medium();

  &:focus {
    border-color: $color-green-medium;
    box-shadow: 0 0 0 1px $color-green-light;
  }
}

.Label {
  display: block;
  margin: 5px;
  @include text-small();
  font-weight: 500;
  color: $color-grey-dark;
}
