@import "../../base.scss";

.Container {
  @include text-medium();
  position: relative;
  user-select: none;
}

.SelectedItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
  background-color: $color-white;
  border-radius: 8px;
  border: 1px solid $color-grey-medium;

  &:hover:not(.Disabled) {
    cursor: pointer;
  }

  &.Active {
    border-color: $color-green-medium;
    box-shadow: 0 0 0 1px $color-green-light;

    .Arrow {
      transform: rotate(-180deg);
    }
  }

  &.Disabled {
    background-color: $color-grey-light;
    color: $color-grey-dark;
  }

  .Arrow {
    transition: transform 250ms ease-in-out;
  }
}

.Label {
  display: block;
  margin: 5px;
  @include text-small();
  font-weight: 500;
  color: $color-grey-dark;
}

.Items {
  box-sizing: border-box;
  width: 100%;
  border-radius: 8px;
  background-color: $color-white;
  position: absolute;
  bottom: -10px;
  right: 0;
  transform: translateY(100%);
  box-shadow: $shadow;
  z-index: 1;
  overflow: hidden;
}

.Item {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 10px;
  height: 40px;

  &:hover:not(.Active) {
    cursor: pointer;
    background-color: $color-blue-ultra-transparent;
  }

  &.Active {
    color: $color-black;
    font-weight: 500;
    background-color: $color-blue-transparent;
  }
}
