@import "../../base.scss";

.Content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Title {
  @include title-medium();
}
