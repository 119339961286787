@import "../../base.scss";

.ArrowButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  span {
    @include text-medium();
    font-weight: 500;
  }

  &:hover {
    cursor: pointer;

    span {
      color: $color-grey-dark;
    }
  }

  .ArrowContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: $color-green-medium;
    border-radius: 50%;
  }
}

.ArrowButton.Plain {
  .ArrowContainer {
    background-color: transparent;
  }
}

.ArrowButton.Reverse {
  flex-direction: row-reverse;

  .ArrowContainer {
    transform: rotate(180deg);
  }
}
