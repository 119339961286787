@import "../../base.scss";

.Bubble {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  border-radius: 12px;
  box-sizing: border-box;
  position: relative;

  .Content {
    width: 100%;
  }

  &.LightBlue {
    background-color: $color-blue-transparent;
  }

  &.LightGreen {
    background-color: $color-green-transparent;
  }

  &.White {
    background-color: $color-white;
  }

  h2 {
    @include title-medium();
  }

  h3 {
    @include title-small();
  }

  p {
    @include text-medium();

    strong {
      font-weight: 500;
    }
  }

  a {
    font-weight: 500;

    &:hover {
      color: $color-grey-dark;
    }
  }
}

.Arrow {
  position: absolute;
  width: 30px;
  height: 30px;
  box-sizing: border-box;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid transparent;

  &.Top {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%) rotate(180deg);
  }

  &.Bottom {
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
  }

  &.Left {
    top: 50%;
    right: 0;
    transform: translate(100%, -50%) rotate(90deg);
  }

  &.Right {
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%) rotate(-90deg);
  }

  &.LightBlue {
    border-bottom-color: $color-blue-transparent;
  }

  &.LightGreen {
    border-bottom-color: $color-green-transparent;
  }

  &.White {
    border-bottom-color: $color-white;
  }
}
