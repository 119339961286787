@import "../../base.scss";

.Footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $color-white;
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  p {
    @include text-small();
    font-weight: 500;
    color: $color-grey-dark;
  }
}

.Social {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Yarn {
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translate(100%, -50%);
}

@media screen and (max-width: $small-screen-breakpoint) {
  .Yarn {
    display: none;
  }
}
