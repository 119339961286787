@import "../../../base.scss";

.Content {
  padding: 40px;
  position: relative;
}

.Title {
  @include title-medium();
  text-align: center;
}

.SubTitle {
  display: block;
  text-align: center;
  @include text-medium();
  color: $color-grey-dark;
  position: absolute;
  top: 10px;
  right: 10px;
  display: none;
}

.BottomContent {
  display: flex;
  align-items: center;
  justify-content: center;
}
