@import "../../base.scss";

.Container {
  position: relative;
}

.Loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-blue-transparent;
  @include text-large();
}
