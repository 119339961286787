@import "../../base.scss";

.Container {
  width: 100%;
  max-width: $content-width;
  margin: 0 auto;
}

.Controls {
  display: flex;
}

.CardLink {
  &:hover {
    > * {
      box-shadow: $shadow-hover;
    }
  }
}

@media screen and (max-width: $small-screen-breakpoint) {
  .Controls {
    display: none;
  }
}
