@import "../../base.scss";

.MarkdownContent {
  h2 {
    @include title-small();
    margin-bottom: 10px;
  }

  p {
    @include text-medium();
    margin-bottom: 20px;

    strong {
      font-weight: 500;
    }
  }

  a {
    font-weight: 500;

    &:hover {
      color: $color-grey-dark;
    }
  }

  ul {
    margin-bottom: 20px;
  }
}
