@import "../../base.scss";

.Container {
  width: 100%;
  max-width: $content-width;
  margin: 0 auto;
}

.LatestPatterns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.CardLink {
  &:hover {
    > * {
      box-shadow: $shadow-hover;
    }
  }
}

@media screen and (max-width: $small-screen-breakpoint) {
  .LatestPatterns {
    flex-direction: column;

    > :not(:last-child) {
      margin-bottom: 20px;
    }

    > * {
      width: 100%;
    }
  }
}
