@import "../../base.scss";

.Container {
  width: $content-width;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Card {
  width: 100%;
  border-radius: 12px;
  background-color: $color-white;
  box-shadow: $shadow;
  overflow: hidden;
}

.Image {
  display: block;
  margin: 0 auto;
  width: 100%;
  height: calc(100% * 9 / 16);
  object-fit: cover;
}

@media screen and (max-width: $small-screen-breakpoint) {
  .Container {
    margin: -20px;
    width: calc(100% + 40px);
  }

  .TopContent {
    display: none;
  }

  .Card {
    border-radius: 0;
    background-color: transparent;
    box-shadow: none;
  }
}
