// Color tokens
$color-black: #333333;
$color-grey-dark: #828282;
$color-grey-medium: #e0e0e0;
$color-grey-light: #f2f2f2;
$color-white: #ffffff;
$color-green-dark: #64a268;
$color-green-medium: #86c481;
$color-green-light: #b1dda3;
$color-green-transparent: rgba(177, 221, 163, 0.25);
$color-blue-dark: #7a9dea;
$color-blue-medium: #96b6f3;
$color-blue-light: #bad0f7;
$color-blue-transparent: rgba(186, 208, 247, 0.25);
$color-blue-ultra-transparent: rgba(186, 208, 247, 0.15);

// Shadow tokens
$shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
$shadow-hover: 0px 4px 16px rgba(0, 0, 0, 0.2);

// Size tokens
$page-width: 1024px;
$content-width: 720px;
$small-screen-breakpoint: 720px;

// Title styles
@mixin title-large {
  font-family: "Giddyup", sans-serif;
  font-weight: 400;
  font-size: 64px;
  line-height: 96px;
}

@mixin title-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
}

@mixin title-small {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}

// Text styles
@mixin text-large {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

@mixin text-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

@mixin text-small {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

// Label styles
@mixin label {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

@mixin button-label {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
